import React from "react";
import { useHistory } from "react-router-dom";
import Section from "./layouts/Section";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ClMImage from "./Image/banner/classic-lite-mode.png";
import CRMImage from "./Image/banner/CLAASIC-RICH-MODE.png";
import PLMImage from "./Image/banner/POPULAR-LITE-MODE.png";
import PRMImage from "./Image/banner/POPULAR-RICH-MODE.png";
import QLMImage from "./Image/banner/QUICK-LITE-MODE.png";
import QRMImage from "./Image/banner/QUICK-RICH-MODE.png";
const Gemms = () => {
  const navigate = useHistory();
  const handleclick = (eve) => {
    if (eve === 1) {
        navigate.push(`/gemm-list/clm`);
    }
    else if (eve === 2) {
        navigate.push(`/gemm-rich/crm`);
    }
    else if (eve === 3) {
        navigate.push(`/gemm-list/plm`);
    }
    else if (eve === 4) {
        navigate.push(`/gemm-rich/prm`);
    }
    else if (eve === 5) {
        navigate.push(`/gemm-list/qlm`);
    }
    else if (eve === 6) {
        navigate.push(`/gemm-rich/qrm`);
    }
   };

  return (
    <>
      <Section>

          {/* transaction start */}
          <div className="transaction-area pd-top-40">
            <div className="container">
                <div className="section-title">
                    <h3 className="title">Challenges</h3>
                    <div to={'/transaction'}>View All</div>
                </div>
                <ul className="transaction-inner">
                    <div className="ba-single-transaction" onClick={() => handleclick(1)}>
                    <div className="thumb">
                            <i className="fa fa-calendar" style={{"color":"#6236ff", fontSize:"32px"}}></i>
                        </div>
                        <div className="details" style={{textAlign:"center", width:"100%"}}>
                            <h5>SURYA MORNING</h5>
                            <p>Open 9:20AM Close 11:20AM</p>
                        </div>
                        <h5 className="btn btn-red" style={{borderRadius:"5px", color:"#5DFF3D"}}>Open</h5>
                        <i className="fa fa-dice" style={{color:"white", fontSize:"32px"}}></i>
                    </div>
                    <div className="ba-single-transaction" onClick={() => handleclick(2)}>
                        <div className="thumb">
                            <i className="fa fa-calendar" style={{"color":"#6236ff", fontSize:"32px"}}></i>
                        </div>
                        <div className="details" style={{textAlign:"center", width:"100%"}}>
                            <h5>MILAN MORNING</h5>
                            <p>Open 10:10AM Close 11:10AM</p>
                        </div>
                        <h5 className="btn btn-red" style={{borderRadius:"5px", color:"#FF3D46"}}>Closed</h5>
                        <i className="fa fa-dice" style={{"color":"white", fontSize:"32px"}}></i>
                    </div>
                    <div className="ba-single-transaction" to="/carts">
                        <div className="thumb">
                            <i className="fa fa-calendar" style={{"color":"#6236ff", fontSize:"32px"}}></i>
                        </div>
                        <div className="details" style={{textAlign:"center", width:"100%"}}>
                            <h5>KALYAN MORNING</h5>
                            <p>Open 11:00AM Close 12:02AM</p>
                        </div>
                        <h5 className="btn btn-red" style={{borderRadius:"5px", color:"#FF3D46"}}>Closed</h5>
                        <i className="fa fa-dice" style={{"color":"white", fontSize:"32px"}}></i>
                    </div>
                    <div className="ba-single-transaction">
                        <div className="thumb">
                            <i className="fa fa-calendar" style={{"color":"#6236ff", fontSize:"32px"}}></i>
                        </div>
                        <div className="details" style={{textAlign:"center", width:"100%"}}>
                            <h5>MADHUR MORNING</h5>
                            <p>Open 9:20AM Close 11:20AM</p>
                        </div>
                            <h5 className="btn btn-red" style={{borderRadius:"5px", color:"#5DFF3D"}}>Open</h5>
                            <i className="fa fa-dice" style={{color:"white", fontSize:"32px"}}></i>
                    </div>
                    {/* <li className="ba-single-transaction">
                        <div className="thumb">
                            <img src={process.env.PUBLIC_URL + '/assets/img/icon/5.png'} alt="img" />
                        </div>
                        <div className="details">
                            <h5>Namecheap Inc.</h5>
                            <p>Domain Purchase</p>
                            <h5 className="amount">-$130</h5>
                        </div>
                    </li> */}
                </ul>
            </div>
        </div>


       
      </Section>
    </>
  );
};

export default Gemms;
