import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import "./signup.css";
const Signup = () => {
  const params = useParams();
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phonevalue, setPhoneValue] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [smessage, setmessage] = useState(null);

  try {
    if (cookies.sg2 === 1) {
      navigate.push("/home");
    }
  } catch (err) {
    console.log(err);
  }
  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      name: e.target[0].value,
      phone_no: e.target[1].value,
      password: e.target[2].value,
      email: e.target[4].value,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}register`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        setmessage(errorData.data[0]);
        setFormErrors(errorData.data);
        setError(`Submission failed: ${errorData.message}`);
      }
      let actualData = await response.json();
      setCookie("ludoT", actualData.data.token);
      setCookie("sg1", "1");
      toast.success("OTP Send Successfully ");
      navigate.push("/otp");
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleMaxLength = (e) => {
    if (e.target.name === "phone") {
      if (e.target.value.length > e.target.maxLength) {
        setPhoneValue(e.target.value.slice(0, e.target.maxLength));
      } else {
        setPhoneValue(e.target.value);
      }
    }
  };

  ////////// password comparison
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [serror, ssetError] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(""); // Reset error when user starts typing
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError(""); // Reset error when user starts typing
  };

  const handlePasswordBlur = () => {
    if (password && confirmPassword && password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  ////////// password comparison

  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={"Signup"}
      sign={true}
    >
      <div className="ba-page-name text-center mg-bottom-40">
        <h3>SignUp</h3>
      </div>
      <div className="signin-area mg-bottom-35">
        <div className="container">
        
          <form className="contact-form-inner" onSubmit={handleLoginsubmit}>
            <label className="single-input-wrap">
              <span>
                {" "}
                Name <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input type="text" required placeholder="Your Name *" />
            </label>
            <label className="single-input-wrap">
              <span>
                Phone Number{" "}
                <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input
                onChange={handleMaxLength}
                name="phone"
                type="number"
                maxLength={10}
                minLength={10}
                max={9999999999} // Maximum value for 3 digits
                min={6000000000} // Minimum value for 3 digits
                required
                value={phonevalue}
                placeholder="98679XXXX9"
                autoComplete

              />
      {formErrors.phone && <span className="errorx">{formErrors.phone[0]}</span>}
            </label>

            <label className="single-input-wrap">
              <span>
                Password
                <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input
                type="password"
                required
                placeholder="Password *"
                value={password}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
              />
              {error && <div style={{ color: "red" }}>{serror}</div>}
            </label>

            <label className="single-input-wrap">
              <span>
                Re-Enter Password
                <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input
                type="password"
                required
                placeholder="Re-Enter Password *"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={handlePasswordBlur}
              />
              {error && <div style={{ color: "red" }}>{serror}</div>}
            </label>

            
            <label className="single-input-wrap">
              <span>Email</span>
              <input type="email" placeholder="Your Email Id" />
              {formErrors.email && <span className="errorx">{formErrors.email[0]}</span>}
            </label>

            {smessage && 
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                {smessage} !
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"> </button>
            </div>}


            <div className="single-checkbox-wrap  align-items-center mb-3">
              <input
                type="checkbox"
                required
                className="margin-minus mr-3"
                style={{ width: "15px", marginBottom: "-25px" }}
              />
              <div className="w-100" style={{ fontSize: "17px",paddingLeft: "25px" }}>
                <span>By continuing, you agree to our </span>
                <Link
                  to="term-legal"
                  style={{ fontSize: "0.788rem", width: "unset" }}
                  className="btn-link border-0 lh-1 bg-transparent "
                >
                  Terms & Conditions.
                </Link>
              </div>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-danger btn-large" disabled>
                Sending OTP...
              </button>
            ) : (
              <button type="submit" className="btn btn-red btn-large">
                Register & Send OTP
              </button>
            )}
              
            <div className="single-checkbox-wrap">
              <p className="text-primary ">
                {" "}
                Do you have an Account?{" "}
                <Link
                  to="/signin"
                  className="btn btn-red text-white margin-top-0 ms-2"
                >
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </Section>
  );
};

export default Signup;
