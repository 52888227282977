import React from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "./layouts/Header";
import { useCookies } from "react-cookie";
const Landing = () => {
  const navigate = useHistory();
  // eslint-disable-next-line
  const [cookies] = useCookies();
  try {
    if (cookies.sg2 === 1) {
      navigate.push("/home");
    }
  } catch (err) {
    console.log(err);
  }

  return (
    <div style={{ maxWidth: 480, height: "100%" }}>
      <Header />

      <div
            className="col-12 mx-auto pt-5"
            
          >
        <div>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/assets/img/loading-banner.png"}
              alt="dice"
              className="w-100"
            />
          </div>
          <div
            className="text-center text-muted"
            style={{ marginTop: "1em", paddingBottom: 10 }}
          >
        
          </div>
          <div
            className="d-flex align-items-center justify-content-center hstack gap-2"
            style={{
              zIndex: 10,
              bottom: "15%",
              left: 20,
              right: 20,
              maxWidth: 440,
            }}
          >
            <div className="d-grid flex-grow-1">
              <Link
                className="btn btn-red btn-lg fw-semibold py-0 mr-2"
                to="signin"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
