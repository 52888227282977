import React, { useRef, useState, useEffect } from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import Gemms from "./Gemm";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import Private from "../privateRoute/Private";
import url from "../environemt";
import { toast } from "react-toastify";
const Home = () => { 
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies();
  const [vsDisplay, setVsDisplay] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const sum = useSelector((state) => state.user.userWallet);
  const withdraw = useSelector((state) => state.user.userWalletWith);
  const navigate = useHistory();
  const closeRef = useRef();
  try {
    if (cookies.sg2 !== 1) {
      navigate.push("/");
    }
  } catch (err) {
    console.log(err);
  }
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [wpoints, setwPoints] = useState('');
  const [smessage, setmessage] = useState(null);

  const token = cookies.ludoM;
  
   ///----------Fetch Data

  const getVS = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    try {
      const response = await fetch(`${url}games_type`, requestOptions);
      if (!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      let actualData = await response.json();
      setError();
      setVsDisplay(actualData.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getVS();
    const interval = setInterval(() => {
      getVS();
    }, 200000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = (encname) => {
    navigate.push(`/gemm-list?id=${encname}`); // Navigate to the gemm list page with the id
  };

/////add deposite

const [fromValue, setFromValue] = useState('');
const [points, setPoints] = useState('');
const [pointsTwo, setPointsTwo] = useState('');
const [account, setAccount] = useState('UPI');
const [formErrors, setFormErrors] = useState({});
const [formErrorsTwo, setFormErrorsTwo] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Serialize form data
    const formData = {
      fromValue,
      points,
      account,
    };

    try {
      // Use fetch with async/await to post data
      const response = await fetch(`${url}payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        
        body: JSON.stringify(formData),
      });

        if (!response.ok) {
            const errorData = await response.json();
            setFormErrors(errorData.data);
            console.log(errorData.data); 
            setError(`Submission failed: ${errorData.message}`);
        } else {
            const result = await response.json();
            setFormErrors({});
            setmessage(result.message);
            // Generate the transaction ID in the format `id#0000user_id`
          const transactionId = `${result.data.id}#0000${result.data.user_id}`;
          console.log('transactionId',transactionId);
          // Construct the payment URL using the transaction ID and points (amount)
         // const paymentUrl = `upi://pay?pa=akbarpredictions7426@ibl&pn=Akbar Predictions&am=${result.data.points}&trt=${transactionId}&cu=INR`;
          // // Open the payment URL
          //window.location.href = paymentUrl;
          
        }
      } catch (err) {
        setError(err.message);
        console.log(error);
      } finally {
        setLoading(false);
      }
  };

  const handleWithdraw = async () => {
    // Serialize form data
    const formData = {
      pointsTwo: pointsTwo,  // Make sure pointsTwo is used for withdrawal
    };
  
    try {
      // Use fetch with async/await to post data
      const response = await fetch(`${url}withdrawal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
      if (!response.ok) {
        // Set form errors from the error response
        setFormErrorsTwo(data.data || {});
        setError(`Submission failed: ${data.message || 'An error occurred'}`);
      } else {
        setmessage(data.message);
        setFormErrorsTwo({});  // Clear errors on success
      }
    } catch (error) {
      setError(`An unexpected error occurred: ${error.message}`);
    }
  };
  
  useEffect(() => {
    // If there's a success message, remove it after 3 seconds
    if (smessage) {
        const timer = setTimeout(() => {
          setmessage(null); // Clear the success message
        }, 2000);

        // Clean up the timeout when component unmounts
        return () => clearTimeout(timer);
    }

    // If there's an error message, remove it after 3 seconds
    if (error) {
        const timer = setTimeout(() => {
          setError(null); // Clear the success message
        }, 2000);

        return () => clearTimeout(timer);
    }
}, [smessage, error]);

 // Function to unregister service workers, clear cache, and reload the page
 const refreshPage = async () => {
  // Unregister all service workers
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let registration of registrations) {
      registration.unregister();
    }
  }

  // Clear the caches (optional but recommended if you want to clear old cached content)
  if ('caches' in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }

  // Reload the page to load fresh content
  window.location.reload(true); // The 'true' parameter forces a reload from the server
};
  return (
    <Private>
      {/* Navigation Bar Imported from Layouts */}
      <Navbar />
      {/* Auto Notification Imported from Layout */}
      {/* Header Starts */}
      <div
        className="header-area header-gradient"
        // style={{ backgroundImage: "url(assets/img/bg/1.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-4">
              <div className="menu-bar">
                <i className="fa fa-bars" />
              </div>
            </div>
            <div className="col-sm-4 col-4 text-center logo-img">
            <Link to={'/'} className="logo text-light">
                <img src={process.env.PUBLIC_URL + '/assets/img/saimat-logo.png'} alt="logo" /> 
            
            </Link>
            </div>
            <div className="col-sm-4 col-4 text-right">
              <ul className="header-right">
                <li>
                  <Link className="header-user" to={"/user-setting"}>
                    
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Header Ends */}
      <div className="add-balance-inner-wrap">
          <div className="container">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title">Add Points</h5>
                      </div>
                      <div className="modal-body">
                          <div className="action-sheet-content">
                          <form onSubmit={handleSubmit}>

    <input type="hidden" value={"UPI"} onChange={(e) => setAccount(e.target.value)}/>
    <input type="hidden" value={"fromValue"} onChange={(e) => setFromValue(e.target.value)}/>
    
      
      <div className="form-group basic">
        <label className="label">Enter Points You Need</label>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="input1">Add Points</span>
          </div>
          <input
            type="text"
            className="form-control form-control-lg"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />
        </div>
        {formErrors.points && <span className="errorx">{formErrors.points[0]}</span>}
      </div>

      <div className="form-group basic">
        <button
          type="submit"
          className="btn-c btn-primary btn-block btn-lg"
        >
          Deposit
        </button>
      </div>
    </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="add-balance-inner-wrap2">
          <div className="container">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title">Withdraw Points</h5>
                      </div>
                      <div className="modal-body">
                          <div className="action-sheet-content">
      <form>
        <div className="form-group basic">
          <label className="label">Enter Points</label>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="input1">Points</span>
            </div>
            <input
              type="text"
              className="form-control form-control-lg"
              value={pointsTwo}
              onChange={(e) => setPointsTwo(e.target.value)}
            />
          </div>
          {formErrorsTwo.pointsTwo && <span className="errorx">{formErrorsTwo.pointsTwo[0]}</span>}
        </div>

        <div className="form-group basic">
          <button
            type="button"
            className="btn-c btn-primary btn-block btn-lg"
            onClick={handleWithdraw}
          >
            Withdraw
          </button>
        </div>
      </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      
      {/* Navbar Ends */}
      {/* balance start */}
      <div className="balance-area pd-top-40 mg-top-50">
        <div className="container">
        {smessage && 
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    {smessage} !
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"> </button>
                </div>}

                {error && 
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    {error} !
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> </button>
                </div>}
          <div className="balance-area-bg balance-area-bg-home">
            <div className="balance-title text-center">
              <h6>
                Welcome,<br/>
                  Dear {userData.name && userData.name}
              </h6>
               <button onClick={refreshPage} className="btn btn-primary">
        Reload Fresh Data
      </button>
{/*             
              <a href="upi://pay?pa=akbarpredictions7426@ibl&amp;pn=Akbar Predictions&amp;am=200&amp;trt=45636&amp;cu=INR" className="btn btn-purple ba-add-balance-btn upi-pay1">Pay Now !</a> */}

            </div>
            <div className="ba-balance-inner text-center">
              <div className="icon mb-0">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/icon/1.png"}
                  alt="img"  
                />
                
              </div>
              <h5 className="title inline mb-0">Total Points</h5>
              <h5 className="amount inline">
                {sum.sumin && (sum.sumin - sum.sumout).toFixed(2)}
              </h5>
            </div>
          </div>
        </div>
      </div>
      {/* balance End */}
      {/* add balance start */}
      <div className="add-balance-area pd-top-40">
        <div className="container">
          <div className="ba-add-balance-title ba-add-balance-btn">
            <h5>Add-Points</h5>

            <i class="fa fa-plus"></i>
          </div>
        
        {/* add balance start */}
        <div className="ba-add-balance-inner mg-top-40">
            <div className="row custom-gutters-20">
                {/* <div className="col-6">
                    <div className="btn btn-blue ba-add-balance-btn2">
                        Withdraw
                        <i className="fa fa-arrow-down" />
                    </div>
                </div>
                <div className="col-6">
                    <div className="btn btn-red ba-add-balance-btn">
                        Add Points
                        <i className="fa fa-plus" />
                    </div>
                </div> */}
                <div className="col-6">
                    <Link className="btn btn-purple ba-add-balance-btn" to={'/'}>
                        Sai Kalyan Starline{/* Cards
                        <i className="fa fa-credit-card-alt" /> */}
                    </Link>
                </div>
                <div className="col-6">
                    <Link className="btn btn-green ba-add-balance-btn" to={'/'}>
                        How To Use This App
                        {/* <i className="fa fa-arrow-down" /> */}
                    </Link>
                </div>
                <div className="col-6">
                    <Link className="btn" to={'/'} style={{border:"1px solid gray"}}>
                        <i className="fa fa-phone pr-2" />
                        8297558369
                    </Link>
                </div>
                <div className="col-6">
                    <Link className="btn" to={'/'} style={{border:"1px solid gray"}}>
                        <i className="fa fa-whatsapp pr-2" />
                        8630688080
                    </Link>
                </div>
                
            </div>
                
        </div>
        {/* transaction start */}
              




        </div>
        
      </div>
      {/* add balance End */}
      {/* Footer Component Imported from Layouts */}
       {/* Game Lis */}
       <div className="transaction-area pd-top-40">
            <div className="container">
                <div className="section-title">
                    <h3 className="title">Challenges</h3>
                    <div to={'/transaction'}>View All</div>
                </div>
                <ul className="transaction-inner">
                {vsDisplay.map((item, index) => (
                    <div className="ba-single-transaction" onClick={() => handleClick(item.encname)} key={index}>
                      <div className="thumb">
                        <i className="fa fa-calendar" style={{ color: "#6236ff", fontSize: "32px" }}></i>
                      </div>
                      <div className="details" style={{ textAlign: "center", width: "100%" }}>
                        <h5>{item.game_name}</h5>
                        <p>Open {item.open_time} Close {item.close_time}</p>
                      </div>
                      <h5 className="btn btn-red" style={{ borderRadius: "5px", color: "#fff", fontWeight:"bold" }}>Open</h5>
                      <i className="fa fa-dice" style={{ color: "white", fontSize: "32px" }}></i>
                    </div>
                  ))}
                </ul>
            </div>
        </div>
        {/* Game Lis */}

        <Footer />
    </Private>
  );
};

export default Home;
