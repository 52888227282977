import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UserVerification = () => {
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(false);
  const [mpin, setMpin] = useState(false);
  const date = moment().add(30, "days").calendar();
  const expire = new Date(date);
  // eslint-disable-next-line
  const [no, token] = cookies.ludoT.split("|");
  try {
    if (cookies.sg2 === 1) {
      navigate.push("/home");
    }
  } catch (err) {
    console.log(err);
  }
  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      otp: e.target[0].value,
      mpin: mpin,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}signup_otp`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data.error);
      }

      let actualData = await response.json();
      console.log(actualData);
      toast.success("MPIN Verified Successfully");
      setCookie("sgmo", "1", { expires: expire });
      setCookie("sg2", "1", { expires: expire });
      navigate.push("/home");
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleFour = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      setOtp(e.target.value.slice(0, e.target.maxLength));
    } else {
      setOtp(e.target.value);
    }
  };
  const handleMaxLength = (e) => {
    if (e.target.name === "mpin") {
      if (e.target.value.length > e.target.maxLength) {
        setMpin(e.target.value.slice(0, e.target.maxLength));
      } else {
        setMpin(e.target.value);
      }
    }
  };

  return (
    <Section allNotification={false} searchPopup={true} title={"Verification"}>
      <div className="ba-page-name text-center mg-bottom-40">
        <h3>Mobile MPIN Verification</h3>
      </div>
      <div className="signin-area mg-bottom-35">
        <div className="container" onSubmit={handleLoginsubmit}>
          <form
            className="verification-inner text-center"
            style={{
              backgroundImage: "url(assets/img/bg/14.png)",
              borderRadius: "25px",
            }}
          >
            <h3>Enter Your 4 Digit OTP </h3>
            <input
              type="number"
              placeholder="...."
              maxLength={4}
              max={9999} // Maximum value for 3 digits
              required
              onChange={handleFour}
              value={otp}
            />

          <h3>Create 4 Digit MPIN </h3>
            <input
              type="number"
              placeholder="...."
              required
              value={mpin}
              name="mpin"
              onChange={handleMaxLength}
              maxLength={4}
              minLength={4}
              max={9999} // Maximum value for 3 digits
            />

                      
            <div className="verify-btn">
              <div className="single-checkbox-wrap  align-items-center mb-3">
                <input
                  type="checkbox"
                  required
                  className="margin-minus mr-3"
                  style={{ width: "15px", marginBottom: "-25px" }}
                />
                <div className="w-100" style={{ fontSize: "17px" }}>
                  <span>By continuing, you agree to our </span>
                  <Link
                    to="term-legal"
                    style={{ fontSize: "0.788rem", width: "unset" }}
                    className="btn-link border-0 lh-1 bg-transparent "
                  >
                    Terms & Conditions.
                  </Link>
                </div>
              </div>
              
            <br/>
            <br/>
            <br/>
              {loading ? (
                <button
                  style={{ width: "200px" }}
                  type="submit"
                  className="btn btn-danger btn-large"
                  disabled
                >
                  Verifying...
                </button>
              ) : (
                <button
                  style={{ width: "200px" }}
                  type="submit"
                  className="btn btn-red btn-large"
                >
                  Verify
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </Section>
  );
};

export default UserVerification;
