import React, { useState, useEffect } from 'react';
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { parse, isAfter, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";

const SigninArea = () => { 
    const timeZone = 'Asia/Kolkata';
    const navigate = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const gt = urlParams.get('gt');
    const [cookies] = useCookies();
    const token = cookies.ludoM;
    
    const currentDate = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        date: currentDate,
        session: '',
        bidDigit: '',
        bidAmount: '',
        gtype: gt,
        gname: id,
        pannavalue:'',
        close_panna:''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [smessage, setmessage] = useState(null);
    const [openTime, setOpenTime] = useState('');
    const [closeTime, setCloseTime] = useState('');
    const [disableOpen, setDisableOpen] = useState(false);
    const [disableClose, setDisableClose] = useState(false);
    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${url}getgamedetails/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();
                
                const openTime = data.data[0].open_time; // e.g., "09:00:00"
                const closeTime = data.data[0].close_time; // e.g., "17:00:00"
                setOpenTime(openTime);
                setCloseTime(closeTime);
            } catch (err) {
                console.error("Failed to fetch data:", err);
                setError("Failed to fetch data.");
            }
        };

        fetchData();
    }, [id, token]);

    useEffect(() => {
        console.log("Inside useEffect:", openTime, closeTime);
        if (openTime && closeTime) {
            const now = new Date();
            const istTime = toZonedTime(now, timeZone);

            // Convert open and close times to Date objects for comparison
            const openDateTime = parse(
                `${format(istTime, 'yyyy-MM-dd')} ${openTime}`,
                'yyyy-MM-dd HH:mm:ss',
                istTime
            );
            const closeDateTime = parse(
                `${format(istTime, 'yyyy-MM-dd')} ${closeTime}`,
                'yyyy-MM-dd HH:mm:ss',
                istTime
            );
            setDisableOpen(isAfter(istTime, openDateTime));
            setDisableClose(isAfter(istTime, closeDateTime));
        }
    }, [openTime, closeTime]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            gdate: formData.date,
            session_type: "none",
            biddigit: "0",
            bidamount: formData.bidAmount,
            gtype: formData.gtype,
            gname: formData.gname,
            pannavalue: formData.open_panna,
            close_panna: formData.close_panna,
        };
        try {
            const response = await fetch(`${url}sendbids`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json" 
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                const errorData = await response.json();
                setFormErrors(errorData.data);
                console.log(errorData.data); 
                setError(`Submission failed: ${errorData.message}`);
            } else {
                const result = await response.json();
                setmessage(result.message);
                setTimeout(() => {
                    navigate.push("/home");
                }, 2000); // Delay navigation by 2000 milliseconds (2 seconds)
            }
        } catch (err) {
            console.error("Error:", err);
            setError("An error occurred during submission.");
        } finally {
            setLoading(false);
        }
    };
//////////////////////////handling
const handlePannaChange = (e) => {
    const { name, value } = e.target;

    // Allow typing but check for max 3 digits
    if (value.length <= 3) {
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: '' }); // Clear any error while typing
    } else {
        setFormErrors({ ...formErrors, [name]: 'Panna value must be exactly 3 digits.' });
    }
};

const handleBlurPanna = (e) => {
    const { name, value } = e.target;

    // On blur, enforce the rule that value should have exactly 3 digits
    if (value.length !== 3) {
        setFormErrors({ ...formErrors, [name]: 'Panna value must be exactly 3 digits.' });
    }
};

    return (
        <Section page={"gemm"} title="Full Sangam"> 
            <div className="signin-area mg-bottom-40" style={{ marginBottom: '15%', marginTop: '8%' }}>
                <div className="container">
                {smessage && 
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    {smessage} !
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"> </button>
                </div>}

                {error && 
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    {error} !
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> </button>
                </div>}
                <form className="contact-form-inner" onSubmit={handleSubmit}>
    <label className="single-input-wrap">
        <span>Date</span>
        <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            style={{ color: "gray" }}
        />
        {formErrors.gdate && <span className="errorx">{formErrors.gdate[0]}</span>}
    </label>

    <label className="single-input-wrap">
        <span style={{ fontWeight: "500", fontSize: "16px", color: "black" }}>Choose Session</span>
        <div className="d-flex mb-2 mt-2">
            <input
                className="d-flex mr-2 ml-2"
                type="number"
                name="open_panna"
                placeholder="Open Panna"
                value={formData.open_panna}
                onChange={handlePannaChange} // Handle input change
                onBlur={handleBlurPanna}
                max={999} // Maximum value for 3 digits
                min={100} // Minimum value for 3 digits
            />
            &nbsp;&nbsp;
            <input
                className="d-flex mr-2 ml-2"
                type="number"
                name="close_panna"
                placeholder="Close Panna"
                value={formData.close_panna}
                onChange={handlePannaChange} // Handle input change
                onBlur={handleBlurPanna}
                max={999} // Maximum value for 3 digits
                min={100} // Minimum value for 3 digits
            />
        </div>
        {formErrors.open_panna && <span className="errorx" style={{ color: "red" }}>{formErrors.open_panna}</span>}
        {formErrors.close_panna && <span className="errorx" style={{ color: "red" }}>{formErrors.close_panna}</span>}
    </label>

    <label className="single-input-wrap">
        <input
            type="number"
            name="bidAmount"
            placeholder="Add Points"
            value={formData.bidAmount}
            onChange={handleChange}
        />
        {formErrors.bidamount && <span className="errorx" style={{ color: "red" }}>{formErrors.bidamount}</span>}
    </label>

    <button type="submit" className="btn btn-red" disabled={loading}>
        {loading ? "Submitting..." : "Submit"}
    </button>
</form>


                </div>
            </div> 
        </Section>
    );
};

export default SigninArea;
