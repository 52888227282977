import React, { useEffect, useState } from "react";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import PointsImg from "./PointsImg";
const TransactionHistory = () => {
// eslint-disable-next-line
const [cookies, setCookie] = useCookies();
const [tranactions, setTranactions] = useState([]);
const [error, setError] = useState([]);
const [vsDisplay, setVsDisplay] = useState([]);
const navigate = useHistory();
const [loading, setLoading] = useState(true);
const token = cookies.ludoM;

///////////////////////////////
///----------Fetch Data
///----------Fetch Data

const getVS = async () => {
const controller = new AbortController();
const signal = controller.signal;
const requestOptions = {
    headers: {
    Authorization: `Bearer ${token}`,
    },
    signal: signal,
};
try {
    const response = await fetch(`${url}rates_info`, requestOptions);
    if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
    }
    let actualData = await response.json();
    setVsDisplay(actualData.data.original);
    setError(null);
} catch (err) {
    setError(err.message);
} finally {
    setLoading(false);
}
};

useEffect(() => {
getVS();
const interval = setInterval(() => {
    getVS();
}, 200000);
return () => {
    clearInterval(interval);
};
// eslint-disable-next-line
}, []);

const formatDate = (datetime) => {
const date = new Date(datetime);
const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
return date.toLocaleDateString('en-GB', options); // 'en-GB' for day-month-year format
};

///////////////////////////////
return (
    <React.Fragment>
    <Section title="Game Rates">
        {/* Game Lis */}
        <div className="transaction-area pd-top-40"  style={{ marginBottom: '15%', marginTop: '0%'  }}>
            <div className="container">
            {vsDisplay.map((item, index) => (
            <div class="single-goal single-goal-one border-gradient">
            <div class="row">
           {/* bidamount,biddigit,gemm_name,gdate,gtype,session_type,status,win_status, */}
                  <div class="col-5 pr-0">
                    <div class="details">
                      <h6>{item.game_name}</h6>
                      <p> {item.gtype} : {item.game_session}  ({formatDate(item.game_date)})</p>
                    </div>
                  </div>
                  <div class="col-3 pl-0">
                    <div class="circle-inner circle-inner-one" style={{ padding: "25px 10px 25px 15px" }}>
                      <h6 class="goal-amount"> {item.bid_points}</h6>
                    </div>
                  </div>
                  <div class="col-3 pl-0">
                    <div class="circle-inner circle-inner-one" style={{ padding: "25px 10px 25px 15px" }}>
                      <p>{item.winning_points}</p>
                    </div>
                  </div>
                

            </div>
            </div>
            ))}
            </div>
        </div>
        {/* Game Lis */}
    </Section>
    </React.Fragment>
);
};

export default TransactionHistory;
