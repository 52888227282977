import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";

import history from "./History";
import Home from "./components/Home";
import About from "./components/About";

import Contact from "./components/Contact";
import Signin from "./components/Signin";
import Signup from "./components/Signup";

import UserSetting from "./components/UserSetting";
import UserVerification from "./components/UserVerification";
import History from "./components/History";
import TermandLegal from "./components/TermandLegal";
import Gemms from "./components/Gemm";
import Gemmlist from "./components/GemmList";
import Gemmrates from "./components/GemmRates";
import Gemmresults from "./components/GemmResults";
import GemmForm from "./components/GemmForm";
import GemmFormJ from "./components/GemmFormJodi";
import GemmFormSp from "./components/GemmFormSinglePanna";
import GemmFormDp from "./components/GemmFormDoublePanna";
import GemmFormTp from "./components/GemmFormTripplePanna";
import GemmFormHs from "./components/GemmFormHalfSangam";
import GemmFormFs from "./components/GemmFormFullSangam";

import TransactionHistory from "./components/TrancstionHistory";
import WalletHistory from "./components/WalletHistory";

import Landing from "./components/Landing";

import Support from "./components/support";

import UserVerificationLogin from "./components/UserVerificationLogin";

class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={"/"} render={(props) => <Landing {...props} />} />
          <Route exact path={"/home"} render={(props) => <Home {...props} />} />
          <Route
            exact
            path={"/about"}
            render={(props) => <About {...props} />}
          />

          {/*<Route exact path={'/components-tooltip'} render={(props) => (<ComponentsTooltip {...props} />)} />*/}
          <Route
            exact
            path={"/contact"}
            render={(props) => <Contact {...props} />}
          />

          <Route
            exact
            path={"/signin"}
            render={(props) => <Signin {...props} />}
          />
          <Route
            exact
            path={"/signup/:refer"}
            render={(props) => <Signup {...props} />}
          />
          <Route
            exact
            path={"/signup"}
            render={(props) => <Signup {...props} />}
          />
          
          <Route
            exact
            path={"/user-setting"}
            render={(props) => <UserSetting {...props} />}
          />
          <Route
            exact
            path={"/otplogin"}
            render={(props) => <UserVerificationLogin {...props} />}
          />
          <Route
            exact
            path="/support"
            render={(props) => <Support {...props} />}
          />
          <Route
            exact
            path="/history"
            render={(props) => <History {...props} />}
          />
          <Route
            exact
            path="/transaction-history"
            render={(props) => <TransactionHistory {...props} />}
          />
          <Route
            exact
            path="/wallet-history"
            render={(props) => <WalletHistory {...props} />}
          />
          <Route
            exact
            path="/term-legal"
            render={(props) => <TermandLegal {...props} />}
          />
          <Route exact path="/gemms" render={(props) => <Gemms {...props} />} />
          <Route
            exact
            path={"/otp"}
            render={(props) => <UserVerification {...props} />}
          />

<Route
            exact
            path="/gemm-list"
            render={(props) => <Gemmlist {...props} />}
          />

          
<Route
            exact
            path="/gemm-rates"
            render={(props) => <Gemmrates {...props} />}
          />

          
<Route
            exact
            path="/gemm-results"
            render={(props) => <Gemmresults {...props} />}
          />

          

<Route
            exact
            path="/gemm-form"
            render={(props) => <GemmForm {...props} />}
          />

<Route
            exact
            path="/gemm-formJ"
            render={(props) => <GemmFormJ {...props} />}
          />

<Route
            exact
            path="/gemm-formSp"
            render={(props) => <GemmFormSp {...props} />}
          />

<Route
            exact
            path="/gemm-formDp"
            render={(props) => <GemmFormDp {...props} />}
          />

<Route
            exact
            path="/gemm-formTp"
            render={(props) => <GemmFormTp {...props} />}
          />
<Route
            exact
            path="/gemm-formHs"
            render={(props) => <GemmFormHs {...props} />}
          />
<Route
            exact
            path="/gemm-formFs"
            render={(props) => <GemmFormFs {...props} />}
          />

        
        
        
        </Switch>
      </Router>
    );
  }
}

export default Routes;
