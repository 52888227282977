import React, { Component } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
const Navigation = () => {
  const path = useLocation();
  const naviagte = useHistory();
  const handleBack = () => {
    if (path.pathname === "/gemms") {
      naviagte.push("/");
    } else {
      naviagte.goBack();
    }
  };
  return (
    <>
      {
        path.pathname !== "/signin" &&
        path.pathname !== "/signup" &&
        path.pathname !== "/otp" &&
        path.pathname !== "/signup2" &&
        path.pathname !== "/otplogin" &&
        path.pathname !== "/adhaarotp" && (
          
             <div className="header-area" style={{backgroundImage: "url(assets/img/bg/1.png)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 col-3">
                                <div className="menu-bar">
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                            <div className="col-sm-4 col-4 text-center">
                                <Link to={'/'} className="logo text-light">
                                    {/* <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="logo" /> */} 
                                    Sai Kalyan
                                </Link>
                            </div>
                            <div className="col-sm-4 col-5 text-right">
                                <ul className="header-right">
                                    <li>
                                        <Link to={'/'}>
                                            <i className="fa fa-envelope" />
                                            <span className="badge">9</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/notification'}>
                                            <i className="fa fa-bell animated infinite swing" />
                                            <span className="badge">6</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="header-user" to={'/user-setting'}>
                                            <img src={process.env.PUBLIC_URL + '/assets/img/saimat-logo.png'} alt="img" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
              

        )}
    </>
  );
};
const Avtar = () => {
  const path = useLocation();
  return (
    <>
      {path.pathname !== "/" &&
        path.pathname !== "/signin" &&
        path.pathname !== "/signup" &&
        path.pathname !== "/otp" &&
        path.pathname !== "/otplogin" &&
        path.pathname !== "/signup2" &&
        path.pathname !== "/adhaarotp" && (
          <li>
            <Link className="header-user" to={"/user-setting"}>
              <img src={process.env.PUBLIC_URL + '/assets/img/saimat-logo.png'} alt="img" />
                                        
            </Link>
          </li>
        )}
    </>
  );
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { search: false };
  }

  render() {
    const searchPopup = (
      <>
        <div
          className={this.state.search ? "body-overlay active" : "body-overlay"}
          id="body-overlay"
          onClick={() => this.setState({ search: false })}
        />
        <div
          className={this.state.search ? "search-popup active" : "search-popup"}
          id="search-popup"
        >
          <form className="search-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search....."
              />
            </div>
            <button type="button" className="submit-btn">
              <i className="fa fa-search" />
            </button>
          </form>
        </div>
      </>
    );

    return (
      <>
        {this.props.searchPopup ? searchPopup : ""}
        <div
          className="header-area header-gradient"
          // style={{ backgroundImage: "url(./assets/img/bg/1.png)" }}
        >
          <div className="container">
            <div className="row d-flex justify-content-between">
              
              <div className="col-sm-4 col-3">
                  <Link className="menu-back-page home-clicked" to={'/'}>
                      <i className="fa fa-angle-left" />
                  </Link>
              </div>
              <div className="col-sm-4 col-6 text-center logo-img">
                <Link to={'/'} className="logo text-light">
                    {/* <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="logo" /> */} 
                    Sai Kalyan
                </Link>
              </div>
              <div className="col-sm-4 col-3 text-right">
                <ul className="header-right">
                  <Avtar />
                </ul>
              </div>
            </div>
        </div>
      </div>
      <div className="page-title mg-top-50">
            <div className="container">
                <Link className="float-left home-clicked" to={'/'}>Home</Link>
                <span className="float-right">{this.props.title}</span>
            </div>
        </div>
            
      </>
    );
  }
}

export default Header;
