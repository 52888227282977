import React, { useState, useEffect } from 'react';
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { parse, isAfter, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";

const SigninArea = () => { 
    const timeZone = 'Asia/Kolkata';
    const navigate = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const gt = urlParams.get('gt');
    const [cookies] = useCookies();
    const token = cookies.ludoM;
    
    const currentDate = new Date().toISOString().split('T')[0];
    const [formData, setFormData] = useState({
        date: currentDate,
        session: '',
        bidDigit: '',
        bidAmount: '',
        gtype: gt,
        gname: id,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [smessage, setmessage] = useState(null);
    const [openTime, setOpenTime] = useState('');
    const [closeTime, setCloseTime] = useState('');
    const [disableOpen, setDisableOpen] = useState(false);
    const [disableClose, setDisableClose] = useState(false);
    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${url}getgamedetails/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();
                
                const openTime = data.data[0].open_time; // e.g., "09:00:00"
                const closeTime = data.data[0].close_time; // e.g., "17:00:00"
                setOpenTime(openTime);
                setCloseTime(closeTime);
            } catch (err) {
                console.error("Failed to fetch data:", err);
                setError("Failed to fetch data.");
            }
        };

        fetchData();
    }, [id, token]);

    useEffect(() => {
        console.log("Inside useEffect:", openTime, closeTime);
        if (openTime && closeTime) {
            const now = new Date();
            const istTime = toZonedTime(now, timeZone);

            // Convert open and close times to Date objects for comparison
            const openDateTime = parse(
                `${format(istTime, 'yyyy-MM-dd')} ${openTime}`,
                'yyyy-MM-dd HH:mm:ss',
                istTime
            );
            const closeDateTime = parse(
                `${format(istTime, 'yyyy-MM-dd')} ${closeTime}`,
                'yyyy-MM-dd HH:mm:ss',
                istTime
            );
            setDisableOpen(isAfter(istTime, openDateTime));
            setDisableClose(isAfter(istTime, closeDateTime));
        }
    }, [openTime, closeTime]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            gdate: formData.date,
            session_type: 'none',
            biddigit: formDataa.bidDigit,
            bidamount: formData.bidAmount,
            gtype: formData.gtype,
            gname: formData.gname,
        };
        console.log(data);
        try {
            const response = await fetch(`${url}sendbids`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json" 
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                const errorData = await response.json();
                setFormErrors(errorData.data);
                console.log(errorData.data); 
                setError(`Submission failed: ${errorData.message}`);
            } else {
                const result = await response.json();
                setmessage(result.message);
                setTimeout(() => {
                    navigate.push("/home");
                }, 2000); // Delay navigation by 2000 milliseconds (2 seconds)
            }
        } catch (err) {
            console.error("Error:", err);
            setError("An error occurred during submission.");
        } finally {
            setLoading(false);
        }
    };
    const [formDataa, setFormDataa] = useState({ bidDigit: '' });
    const [errorMessage, setErrorMessage] = useState('');
     // Function to handle input change
    const handleChangea = (e) => {
        const { name, value } = e.target;

        // Allow only numbers and restrict length to between 1 and 3 digits
        if (/^\d*$/.test(value) && value.length <= 2) {
            setFormDataa({ ...formDataa, [name]: value });
            setErrorMessage(''); // Clear error message
        }    };

    // Function to handle input blur (to enforce min length)
    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (value.length < 2) {
            // Optional: Set value to an empty string or handle as needed
            setFormDataa({ ...formDataa, [name]: '' });
            setErrorMessage('only 2 numbers are allowed');    
        }else if (value.length == 2) {
            // Optional: Set value to an empty string or handle as needed
            setErrorMessage('');    
        }

    };

  

    return (
        <Section page={"gemm"} title="Jodi"> 
            <div className="signin-area mg-bottom-40" style={{ marginBottom: '15%', marginTop: '8%' }}>
                <div className="container">
                {smessage && 
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    {smessage} !
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"> </button>
                </div>}

                {error && 
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    {error} !
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> </button>
                </div>}
                    <form className="contact-form-inner" onSubmit={handleSubmit}>
                    
                        <label className="single-input-wrap">
                            <span>Date</span>
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                style={{ color: "gray" }}
                            />
        {formErrors.gdate && <span className="errorx">{formErrors.gdate[0]}</span>}
                        </label>
                        

                        <label className="single-input-wrap">
                            <input
                                type="number"
                                name="bidDigit"
                                placeholder="Select Jodi Number"
                                value={formDataa.bidDigit}
                                onChange={handleChangea} // Handle change event
                                onBlur={handleBlur} // Handle blur event
                                maxLength={2} // Limit input length to 3 characters
                            />
                            {/* Display error message if validation fails */}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {formErrors.biddigit && <span className="errorx">{formErrors.biddigit[0]}</span>}
                        </label>
                        <label className="single-input-wrap">
                            <input
                                type="number"
                                name="bidAmount"
                                placeholder="Add Points"
                                value={formData.bidAmount}
                                onChange={handleChange}
                            />
                        {formErrors.bidamount && <span className="errorx">{formErrors.bidamount[0]}</span>}
                        </label>
                        <button type="submit" className="btn btn-red" disabled={loading}>
                            {loading ? "Submitting..." : "Submit"}
                        </button>
                    </form>

                </div>
            </div> 
        </Section>
    );
};

export default SigninArea;
