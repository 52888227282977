import React, { useState } from "react";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import url from "../environemt";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
const Signin = () => {
  localStorage.clear();
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [phonevalue, setPhoneValue] = useState("");
  const [loading, setLoading] = useState(false);
  try {
    if (cookies.sg2 === 1) {
      navigate.push("/home");
    }
  } catch (err) {
    console.log(err);
  }
  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      phone_no: e.target[0].value,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}login`, requestOptions);
      let actualData = await response.json();
      if (!response.ok) {
        toast.error(actualData.data[0]);
      }
      if (actualData.success) {
        setCookie("ludoT", actualData.data.token);
        setCookie("sg1", "1");
        toast.success("Your Phone number is validated");
        navigate.push("/otplogin");
      }else{
        toast.error("Phone Number is not verified");
      }

    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleMaxLength = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      setPhoneValue(e.target.value.slice(0, e.target.maxLength));
    } else {
      setPhoneValue(e.target.value);
    }
  };
  return (
    <Section allNotification={false} searchPopup={true} title={"Signin"}>
      <div className="ba-page-name text-center mg-bottom-40">
        <h3>Login</h3>
      </div>
      <div className="signin-area mg-bottom-35">
        <div className="container">
          <form className="contact-form-inner" onSubmit={handleLoginsubmit}>
            <label className="single-input-wrap">
              <span>
                Enter Mobile Number
                <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input
                type="number"
                placeholder="Mobile No."
                required
                value={phonevalue}
                onChange={handleMaxLength}
                maxLength={10}
                minLength={10}
                max={9999999999} // Maximum value for 3 digits
                min={6000000000} // Minimum value for 3 digits
              />
            </label>
            <br/>
            <br/>
            <br/>
            {loading ? (
              <button className="btn btn-danger btn-large" type="submit" disabled>
                ...loging in
              </button>
            ) : (
              <button className="btn btn-red btn-large" type="submit">
                Login
              </button>
            )}
            <div
              className="d-flex align-items-end  mt-3"
              style={{ gap: "15px" }}
            >
              <p className="mt-2">
                <br />
                Don't Have An Account?{" "}
              </p>
              <Link className="btn btn-red btn-large mt-0" to="/signup">
                Register
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Section>
  );
};

export default Signin;
