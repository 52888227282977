import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { FaDice } from "react-icons/fa";
import { IoDice } from "react-icons/io5";
import { GiCard2Spades, GiCard3Spades, GiCardAceSpades } from "react-icons/gi";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import secureLocalStorage from "react-secure-storage";

const Gamelist = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');


    return (
    <> 
    <Section page={"gemm"} title="Gemms Type">
    <div className="cart-area pd-top-36 mg-bottom-40"  style={{ marginBottom: '8%'}}>
    <div className="container">
            <div className="section-title">
                {/* <h3 className="title">Choose Market</h3> */}
                {/* <Link className="count-title" to={'/carts'}>5</Link> */}
            </div>
            <div className="d-flex justify-content-around">
                <div className="btn-blue ba-cart-inner mb-3 bg-cart-img1 " style={{ backgroundImage: "url('../../assets/img/bg/8.png') !important" }}>

                <Link to={`/gemm-form?gt=s&id=${id}`}><h4>Single</h4>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <IoDice color="white" size={"50px"} /></div></Link>
                </div>
                <div className="btn-red ba-cart-inner mb-3 bg-cart-img2" style={{ backgroundImage: "url(../../assets/img/bg/10.png) !important" }}>
                <Link to={`/gemm-formJ?gt=j&id=${id}`}> <h4>Jodi </h4>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <FaDice color="white" size={"50px"} />

                        </div>
                </Link>
                </div> 

                <div className="btn-purple ba-cart-inner mb-3 bg-cart-img3" >

                <Link to={`/gemm-formSp?gt=sp&id=${id}`}>  <h4>Single Pana</h4>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <GiCardAceSpades color="white" size={"50px"} />
                        </div>
                </Link>
                </div>
            </div>
            <div className="d-flex justify-content-around">
                <div className="btn-blue ba-cart-inner mb-3 bg-cart-img4">

                <Link to={`/gemm-formDp?gt=dp&id=${id}`}><h4>Double Pana</h4>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <GiCard2Spades color="white" size={"50px"} />
                        </div>      
                </Link>     
                </div>

                <div className="btn-green ba-cart-inner mb-3 bg-cart-img5">

                <Link to={`/gemm-formTp?gt=tp&id=${id}`}><h4>Triple Pana</h4>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <GiCard3Spades color="white" size={"50px"} /></div>
                </Link>

                </div>
                <div className="btn-blue ba-cart-inner mb-3 bg-cart-img1">

                <Link to={`/gemm-formHs?gt=hs&id=${id}`}><h4>Half Sangam</h4>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <IoDice color="white" size={"50px"} />
                        <GiCardAceSpades color="white" size={"50px"} />
                    </div>
                    
                </Link>
    
                </div>

            </div>
            <div className="d-flex justify-content-center">
                <div className="btn-red ba-cart-inner center bg-cart-img2" style={{ marginBottom:"10%" }}>
                <Link to={`/gemm-formFs?gt=fs&id=${id}`}>
                
                    <h4>Full Sangam</h4>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <FaDice color="white" size={"50px"} />
                        <GiCardAceSpades color="white" size={"50px"} />
                    </div>
                </Link>


                </div>

            </div>

        </div>
    </div>
      </Section>
    </>
  );
};

export default Gamelist;
