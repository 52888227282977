import React, { useEffect, useState } from "react";
import Section from "./layouts/Section";

import { useDispatch, useSelector } from "react-redux";
import url from "../environemt";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { addUser } from "../store/slicer";

const UserSetting = () => {
  const [edit, setEdit] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [userMatrix, setUserMatrix] = useState();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  var token = cookies.ludoM;
  const updateUsername = async (e) => {
    e.preventDefault();
    const data = {
      username: e.target[0].value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}users_update`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.data[0]) {
          toast.error(errorData.data[0]);
        }
      }
      let actualData = await response.json();
      dispatch(addUser(actualData.data));
      if (actualData.success) {
        toast.success("UserName Updated Successfully");
      }
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setLoading(false);
      setEdit(!edit);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}userSetting`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setUserMatrix(actualData.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  return (
    <Section allNotification={false} searchPopup={true} title={"User Setting"}>
      {loading ? (
        <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="balance-area pd-top-40">
            <div className="container">
              {/* <div className="section-title" >
                        <h3 className="title">Check Your Information</h3>
                    </div> */}
              <div className="balance-area-bg pt-5 bg-user-setting">
                {/* <Link className="edit-title" to={'/user-setting'}>Edit</Link> */}
                <div
                  className="ba-balance-inner text-center"
                  style={{ backgroundImage: "url(assets/img/bg/2.png)" }}
                >
                  <div className="icon">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/saimat-logo.png"}
                      alt="img"
                    />
                  </div>
                  <h5 className="title mb-0">
                    {userData.name && userData.name}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="transaction-details pd-top-36 mg-bottom-35">
            <div className="container">
              <ul className="transaction-details-inner">
                {/* <li>
                            <span className="float-left">Notification Sound</span>
                            <span className="float-right">Beep</span>
                        </li> */}
                <li>
                  <div
                    className="d-flex justify-content-center
                  align-items-center gap-15px
                  "
                  >
                    <span className="float-left"> Username</span>
                    <div className={!edit ? "d-flex align-items-center" : ""}>
                      {!edit ? (
                        <>
                          <input
                            className="form-control form-control-xxl-lg my-3  d-inline-block mr-3 "
                            type="text"
                            placeholder="User name"
                            defaultValue={
                              userData.username && userData.username
                            }
                            readOnly
                          />
                          <button
                            className="btn btn-primary px-4 btn-sm py-0"
                            onClick={() => setEdit(!edit)}
                          >
                            Edit
                          </button>
                        </>
                      ) : (
                        <>
                          <form
                            onSubmit={updateUsername}
                            className="d-flex align-items-center"
                          >
                            <input
                              className="form-control form-control-xxl-lg my-3  d-inline-block mr-3 "
                              type="text"
                              placeholder="User name"
                              defaultValue={
                                userData.username && userData.username
                              }
                            />
                            <button
                              className="btn btn-primary px-4 btn-sm py-0"
                              type="submit"
                            >
                              Save
                            </button>
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="d-flex justify-content-center
                  align-items-center gap-100px"
                  >
                    <span className="float-left">Name </span>
                    <div>
                      <input
                        className="form-control form-control-xxl-lg my-3 d-inline-block mr-3 "
                        type="text"
                        placeholder="Phone no"
                        defaultValue={userData.name && userData.name}
                        readOnly
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="d-flex justify-content-center
                  align-items-center gap-42px"
                  >
                    <span className="float-left">Mobile </span>
                    <div style={{ flex: "1 0 73%" }}>
                      <input
                        className="form-control form-control-xxl-lg my-3 d-inline-block mr-3 "
                        type="text"
                        placeholder="Phone no"
                        defaultValue={userData.phone_no && userData.phone_no}
                        readOnly
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="d-flex justify-content-center
                  align-items-center gap-24px"
                  >
                    <span className="float-left">Aadhaar </span>
                    <div style={{ flex: " 1 0 72%" }}>
                      <input
                        className="form-control form-control-xxl-lg my-3 d-inline-block mr-3 "
                        type="text"
                        placeholder="Phone no"
                        defaultValue={userData.adhar_no && userData.adhar_no}
                        readOnly
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="border shadow">
                    <div className="d-flex flex-column mb-4 justify-content-center align-items-center bg-primary">
                      <span className="">Metrics </span>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-sm border px-0 m-3">
                          <div className="bg-warning text-center py-1">
                            Total Played
                          </div>
                          <div className="text-center py-3">
                            {userMatrix.total_gemm
                              ? userMatrix.total_gemm
                              : "0"}
                          </div>
                        </div>
                        <div className="col-sm border px-0 m-3">
                          <div className="bg-info text-center py-1">
                            Points Wins
                          </div>
                          <div className="text-center py-3">
                            {userMatrix.wallet_winning
                              ? userMatrix.wallet_winning
                              : "0"}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm border px-0 m-3">
                          <div className="bg-success text-center py-1">
                            Referral Earning
                          </div>
                          <div className="text-center py-3">
                            {userMatrix.referalEarnings
                              ? userMatrix.referalEarnings
                              : "0"}
                          </div>
                        </div>
                        <div className="col-sm border px-0 m-3">
                          <div className="bg-danger text-center py-1">
                            Penality
                          </div>
                          <div className="text-center py-3">
                            {userMatrix.total_penalty
                              ? userMatrix.total_penalty
                              : "0"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </Section>
  );
};

export default UserSetting;
