import React from "react";
import Section from "./layouts/Section";
import TermRefund from "./TermRefund";
import TermLegal from "./TermLegal";
const TermandLegal = () => {
  return (
    <>
      <Section>
        <div className="col-xl-12" style={{ marginTop: "70px" }}>
          {/* Begin Icons 01 */}
          <div id="accordion-icon-left" className="accordion-icon icon-01">
            {/* Begin Widget */}
            <div className="widget has-shadow">
              <div className="widget-header bordered no-actions d-flex align-items-center justify-content-center">
                <h4> Term and Condition </h4>
              </div>
              <a
                className="card-header collapsed d-flex align-items-center"
                data-toggle="collapse"
                href="#IconLeftCollapseOne"
              >
                <div className="card-title">Terms & Conditions </div>
              </a>
              <div
                id="IconLeftCollapseOne"
                className="card-body collapse pt-0"
                data-parent="#accordion-icon-left"
              >
                <p> We are just an information website, we gave login access only to record our users interest and give them the best always.</p>
              </div>
              
            </div>
            {/* End Widget */}
          </div>
          {/* End Icons 01 */}
        </div>
      </Section>
    </>
  );
};

export default TermandLegal;
