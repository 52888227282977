import React, { Component } from 'react';
import Routes from './Routes'; // Assuming this file handles your route navigation
import './App.css';
import { App as CapacitorApp } from '@capacitor/app'; // Import the App plugin from Capacitor

class App extends Component {
componentDidMount() {
    // Handle Android back button
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (canGoBack) {
        window.history.back(); // Navigate to the previous page if possible
    } else {
        this.showExitConfirm(); // Show an exit confirmation if there's nowhere to go back
    }
    });
}

showExitConfirm = () => {
    if (window.confirm("Are you sure you want to exit the app?")) {
    CapacitorApp.exitApp(); // Exit the app if the user confirms
    }
};

componentWillUnmount() {
    // Remove event listener when the component is unmounted
    CapacitorApp.removeAllListeners();
}

render() {
    return (
    <Routes />  // Your app's routing logic or components
    );
}
}

export default App;
